import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

import WrappedField from './WrappedField';
import Button from './Button';
import Form from './Form';

import { encode } from '../helpers/encode';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  surname: Yup.string().required('Surname is required'),
  email: Yup.string()
    .email('This is not a valid email')
    .required('Email is required'),
  company: Yup.string(),
  phone: Yup.string()
});

const defaultProps = {
  title: '',
  date: ''
}

const initialValues = {
  name: '',
  surname: '',
  email: '',
  company: '',
  phone: ''
};


class WorkshopForm extends React.Component {
  constructor(props) {
    super(props);

    this.idHTML = 'workshopForm';
    this.netlifyFormName = 'workshopForm';
    this.netlifyHoneypotName = 'tricky-field';
    this.fieldNames = [
      'name',
      'surname',
      'email',
      'company',
      'phone'
    ];

    this.state = {
      hasSubmitSucceeded: false,
      hasSubmitFailed: false,
    };
  }

  get isNetlifyFormActive() {
    return process.env.GATSBY_IS_NETLIFY_FORM_ACTIVE === 'TRUE';
  }

  prepareData = values => {
    const { fieldNames, isNetlifyFormActive } = this;

    const netlifyData = isNetlifyFormActive
      ? { 'form-name': this.netlifyFormName }
      : {};

    return fieldNames.reduce((acc, fieldName) => {
      const val =
        typeof values[fieldName] === 'object'
          ? values[fieldName].value
          : values[fieldName];
      acc[fieldName] = val || '';
      return acc;
    }, netlifyData);
  };

  sendForm = async data => {
    const res = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    });
    const newState = res.ok
      ? { hasSubmitSucceeded: true }
      : { hasSubmitFailed: true };
    this.setState(newState);

    navigate('#form-success');
  };

  onSubmit = values => {
    const data = this.prepareData(values);
    const dataExtended = {
      ...data,
      ...{'workshop': this.props.title}
    };

    this.sendForm(dataExtended);
  };

  render() {
    const { hasSubmitSucceeded, hasSubmitFailed } = this.state;

    const netlifyProps = this.isNetlifyFormActive
      ? {
          name: this.netlifyFormName,
          'data-netlify': true,
          'data-netlify-honeypot': this.netlifyHoneypotName,
        }
      : {};

    let submit = (
      <Button type="submit" className="Form__submit Form__submit--left" variants={['wide', 'textNoTransform']}>
        Register
      </Button>
    );
    if (hasSubmitSucceeded) {
      submit = (
        <p className="Form__submit">
          Thanks for reaching out! We will get back to you shortly.
        </p>
      );
    } else if (hasSubmitFailed) {
      submit = (
        <p className="Form__submit">
          Your form was <strong>not</strong> saved. Please reach us at{' '}
          <strong>hello@getindata.com</strong>. Thanks!
        </p>
      );
    }

    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      >
        {({ errors, touched, setFieldValue, setFieldTouched, values }) => (
          <Form {...netlifyProps} className="WorkshopForm" id={this.idHTML}>
            {this.isNetlifyFormActive && (
              <input type="hidden" name={this.netlifyHoneypotName} />
            )}
            {this.isNetlifyFormActive && (
              <input
                type="hidden"
                name="form-name"
                value={this.netlifyFormName}
              />
            )}

            <input
              type="hidden"
              name="idHTML"
              value={this.idHTML}
            />

            <input
              type="hidden"
              name="workshop"
              value={this.netlifyFormName}
            />

            <div className="Form__container Form__container--twoThirds">
              {this.fieldNames.map((item, i) => (
                <div className="Form__level" key={i}>
                  <WrappedField
                    name={item}
                    label={capitalizeFirstLetter(item)}
                    type="text"
                    error={errors[item]}
                    isTouched={touched[item]}
                    placeholder={"Type your " + item}
                  />
                </div>
              ))}
            </div>

            <p className="Form__textAnnotation">
              Before you register please read carefully the &nbsp;
              <Link to="/training-terms-and-conditions" target="_blank">Terms & Conditions</Link>
              &nbsp; of our training.
            </p>

            {submit}
          </Form>
        )}
      </Formik>
    );
  }
}

WorkshopForm.defaultProps = defaultProps;

export default WorkshopForm;
