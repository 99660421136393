import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import Sticky from 'react-stickynode';
import pluralize from 'pluralize';
import moment from 'moment';
import xss from 'xss';
import { navigate } from '@reach/router';

import DataContactPersons from '../data/contactPersons.json';
import DataTestimonials from '../data/testimonials.json';
import scrollTo from '../helpers/scrollTo';
import htmlFromMarkdown from '../helpers/htmlFromMarkdown';
import generateImgAlt from '../helpers/generateImgAlt';

import BackgroundClientsSrc from '../img/svg/pageHome/conference_bg.svg';
import BackgroundMainSrc from '../img/svg/pageWorkshop/main_bg.svg';
import ReferenceSwiper from '../components/ReferenceSwiper';
import Layout from '../components/Layout';
import Button from '../components/Button';
import WorkshopsRoll from '../components/WorkshopsRoll';
import Markdown from '../components/Markdown';
import WorkshopForm from '../components/WorkshopForm';

class WorkshopPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.card = React.createRef();
    this.cardCol = React.createRef();

    this.state = {
      cardOffsetTop: 0,
      cardHeight: null
    };

    this.resizeEvent = this.calculateOffsetTop.bind(this);
  }

  componentDidMount() {
    this.setState({
      cardHeight: this.card.current.clientHeight
    });

    setTimeout(() => { this.calculateOffsetTop() }, 0);

    window.addEventListener("resize", this.resizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  calculateOffsetTop() {
    const offsetTop = this.card.current.getBoundingClientRect().top;

    this.setState({ cardOffsetTop: offsetTop });
  }

  handleToggleForm() {
    navigate('#form');
  }

  renderDate(date, duration) {
    let result;

    if (!isNaN(new Date(date))) {
      let dateISO = new Date(date).toISOString();
      if (duration > 1) {
        let start = moment(dateISO);
        let startDay = moment(start).format('D');
        let startMonth = moment(start).format('MMMM');
        let startYear = moment(start).format('YYYY');
        let end = moment(start).add(duration - 1, 'days').endOf('day');
        let endDay = end.format('D');
        let endMonth = end.format('MMMM');

        if (start.isSame(end, 'month')) {
          result = `${startDay}-${endDay} ${endMonth} ${startYear}`;
        } else {
          result = `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
        }
      } else {
        result = moment(dateISO).format('D MMMM YYYY');
      }
    } else {
      result = 'Not scheduled';
    }

    return result;
  }

  render() {
    const dataPost = this.props.markdownRemark.frontmatter;
    const dataPostId = this.props.markdownRemark.id;
    const dataHtml = this.props.markdownRemark.html;
    const dataWorkshops = this.props.allMarkdownRemark.edges;

    const { location } = this.props;
    const {
      cardOffsetTop,
      cardHeight
    } = this.state;
    const {
      image,
      title,
      description,
      subtitle,
      subtext,
      courseAgenda,
      contactPersons,
      footerText
    } = dataPost;

    const {
      duration,
      target,
      where,
      technology,
      price,
      date
    } = dataPost.box;

    const hash = location.hash;
    const isVisibleForm = (hash === '#form' || hash ===  '#form-success') ? true : false;
    const isDateNotValid = isNaN(new Date(date));
    const durationText = duration + '-' + pluralize('day', parseInt(duration));
    const dateText = this.renderDate(date, duration);
    const subtitleAgenda = duration > 1 ? 'Day' : 'Part';
    const navbarHeight = 80;
    const contPaddingTop = 50;
    const isSticky =
      cardHeight + navbarHeight + contPaddingTop <=
        (typeof window !== 'undefined' && window.innerHeight) ? true : false;

    const contactPersonsDataFiltered =
      DataContactPersons.items && contactPersons ?
        DataContactPersons.items.filter(o => contactPersons.find(o2 => o.id === o2.value)) : [];

    const filteredDataWorkshops = dataWorkshops.reduce((prevVal, currentValue) => {
      let slugFrontmatter = currentValue.node.frontmatter.slug;
      let slug = slugFrontmatter ? slugFrontmatter : currentValue.node.fields.slug;

      // exclude current workshop and transform workshop data
      return dataPostId !==
        currentValue.node.id ?
        [...prevVal, {
          ...currentValue.node.frontmatter,
          slug: slug // should be last
        }] : prevVal
    }, []);

    let btnContact = '';

    if (isDateNotValid === false && !isVisibleForm) {
      btnContact = (
        <Button className="Workshop__card__btn" onClick={this.handleToggleForm.bind(this)}>
          Make Reservation
        </Button>
      );
    } else if (isDateNotValid || !date) {
      btnContact = (
        <Button className="Workshop__card__btn" onClick={() => scrollTo('sectionContact')}>
          Contact Us
        </Button>
      );
    }

    return (
      <div className="Workshop">
        <section className="Workshop__main">
          <div className="Workshop__testimonials__background">
            <SVG
              src={BackgroundMainSrc}
              uniquifyIDs={false}
              className="Workshop__testimonials__background-image"
            />
          </div>
          <div className="Workshop__container">
            <div className="Workshop__container__col" ref={this.cardCol}>
              <div className="Workshop__container__col__elem">
                <Sticky top={cardOffsetTop} enabled={isSticky} bottomBoundary=".Workshop__container__col">
                  <div className="Workshop__card" ref={this.card}>
                    <div className="Workshop__card__header">
                      <div className="Workshop__card__header__subtitle">Next term</div>
                      <div className="Workshop__card__header__date">{dateText}</div>
                      {(isDateNotValid || !date) &&
                        <div className="Workshop__card__header__underText">
                          If you are interested, please contact us
                          </div>
                      }
                    </div>
                    <div className="Workshop__card__content">
                      {duration &&
                        <div className="Workshop__card__content__row">
                          <div className="Workshop__card__content__label">Duration</div>
                          <div className="Workshop__card__content__text">{durationText}</div>
                        </div>
                      }
                      {target &&
                        <div className="Workshop__card__content__row">
                          <div className="Workshop__card__content__label">Target audience</div>
                          <div className="Workshop__card__content__text">{target}</div>
                        </div>
                      }
                      {where &&
                        <div className="Workshop__card__content__row">
                          <div className="Workshop__card__content__label">Place</div>
                          <div className="Workshop__card__content__text">{where}</div>
                        </div>
                      }
                      {technology &&
                        <div className="Workshop__card__content__row">
                          <div className="Workshop__card__content__label">Technology</div>
                          <div className="Workshop__card__content__text">{technology}</div>
                        </div>
                      }
                      {price &&
                        <div className="Workshop__card__content__row">
                          <div className="Workshop__card__content__label">Price</div>
                          <div className="Workshop__card__content__text">{price}</div>
                        </div>
                      }
                      {btnContact &&
                        <div className="Workshop__card__content__row">
                          {btnContact}
                        </div>
                      }
                    </div>
                  </div>
                </Sticky>
              </div>
            </div>
            <div className="Workshop__container__col">
              {!isVisibleForm ? (
                <>
                  <img src={image.publicURL || image} className="Workshop__icon" alt={generateImgAlt(image.name)} />
                  <h1 className="Workshop__title">{title}</h1>
                  <p className="Workshop__description">{description}</p>

                  {dataHtml &&
                    <>
                      <div className="Workshop__separate"></div>
                      <div className="Workshop__body" dangerouslySetInnerHTML={{ __html: xss(dataHtml) }} />
                    </>
                  }

                  <div className="Workshop__separate"></div>

                  <h3 className="Workshop__subtitle">{subtitle}</h3>
                  <Markdown content={subtext} className="Workshop__subtext" />

                  <div className="Workshop__separate"></div>

                  <h3 className="Workshop__subtitle">Course agenda<sup>*</sup></h3>

                  <div className="Workshop__containerCourses">
                    <div className="Workshop__containerCourses__inner">
                      {courseAgenda && courseAgenda.parts.map((part, i) => (
                        <div key={i} className="Workshop__containerCourses__row">
                          <div className="Workshop__course">
                            <h5 className="Workshop__course__subtitle">{subtitleAgenda} {i + 1}</h5>
                            {part.title &&
                              <h4 className="Workshop__course__title">{part.title}</h4>
                            }
                          </div>
                          <ul className="Workshop__course__list">
                            {part.items && part.items.map((item, i) => (
                              <li key={i}>
                                <div dangerouslySetInnerHTML={{ __html: xss(htmlFromMarkdown(item.text)) }} />
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="Workshop__textAnnotation">
                    <sup>*</sup> GetInData reserves the right to make any changes and adjustments to the presented agenda.
                  </div>

                  {(footerText && footerText.items) &&
                    <>
                      <div className="Workshop__separate"></div>

                      <div className="Workshop__footerText">
                        {footerText && footerText.items.map((item, i) => (
                          <div className="Workshop__footerText__row" key={i}>
                            <h4 className="Workshop__subtitle">{item.title}</h4>
                            <div className="Workshop__subtext">{item.text}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  }

                  <div className="Workshop__separate"></div>

                  {contactPersonsDataFiltered.length > 1 &&
                    <h3 className="Workshop__subtitle">Contact person</h3>
                  }
                  <div className="Workshop__container-user">
                    {contactPersonsDataFiltered.map((person, i) => (
                      <div className="Workshop__user" key={i}>
                        <div className="Workshop__user__col">
                          <div className="Workshop__user__avatar" style={{ backgroundImage: `url(${person.avatar})` }}></div>
                        </div>
                        <div className="Workshop__user__col">
                          <div className="Workshop__user__name">{person.name}</div>
                          <div className="Workshop__user__email">{person.email}</div>
                          <div className="Workshop__user__phone">{person.phone}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                  <div className="Workshop__form">
                    <div className="Workshop__form__preTitle">Registration Form</div>
                    <h2 className="Workshop__form__title">{title}</h2>
                    {date &&
                      <div className="Workshop__form__date">
                        (
                          {where ? where + ', ' : ''}
                          {dateText}
                        )
                      </div>
                    }
                    <div className="Workshop__form__cont">
                      <WorkshopForm title={title} />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>

        <section className="Workshop__testimonials">
          <div className="Workshop__testimonials__background">
            <SVG
              src={BackgroundClientsSrc}
              uniquifyIDs={false}
              className="Workshop__testimonials__background-image"
            />
          </div>
          <div className="Workshop__testimonials__container">
            <h2 className="Workshop__testimonials__title">Testimonials</h2>
            <ReferenceSwiper data={DataTestimonials.workshops} />
          </div>
        </section>

        <section className="Workshop__trainings">
          <WorkshopsRoll data={filteredDataWorkshops} title="Other Big Data Training" />
        </section>
      </div>
    );
  }
};

const WorkshopPost = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  const mergedProps = { ...props.data, ...{ location: props.location } };

  let contactSuccessCb = null;
  const pathMlops = "/workshops/machine-learning-operations-training-mlops";
  if(typeof window !== 'undefined' && window.location.href.indexOf(pathMlops)) {
    contactSuccessCb = () => { window.lintrk('track', { conversion_id: 8790442 }) };
  }

  return (
    <Layout
      variants={['backgrounded']} 
      meta={frontmatter.meta} 
      contactSuccessCb={contactSuccessCb}
    >
      <WorkshopPostTemplate {...mergedProps} />
    </Layout>
  );
};

WorkshopPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default WorkshopPost;

export const pageQuery = graphql`query WorkshopPostByID($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    html
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      title
      description
      box {
        where
        duration
        target
        technology
        price
        date
      }
      subtitle
      subtext
      featuredWorkshop
      image {
        publicURL
        name
      }
      courseAgenda {
        parts {
          title
          items {
            text
          }
        }
      }
      contactPersons {
        label
        value
      }
      footerText {
        items {
          title
          text
        }
      }
    }
  }
  allMarkdownRemark(
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "workshop-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredWorkshop
          slug
          image {
            publicURL
            name
          }
          is_new_date
        }
      }
    }
  }
}`
