import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import html from 'remark-html';

export default function htmlFromMarkdown(markdown) {
  let htmlData;

  remark()
    .use(recommended)
    .use(html)
    .process(markdown, function(err, file) {
      if(err) {
        console.error(err);
      }
      htmlData = file.contents;
    })

  return htmlData;
}
