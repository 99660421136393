import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import WorkshopItem from './WorkshopItem';

const WorkshopsRoll = ({data, title, text, svg}) => {
  return(
    <section className="WorkshopsRoll">
      {svg &&
        <div className="WorkshopsRoll__background">
          <SVG
            src={svg}
            uniquifyIDs={false}
            className="WorkshopsRoll__background-image"
          />
        </div>
      }
      <div className="WorkshopsRoll__container">
        <div className="WorkshopsRoll__content">
          {title &&
            <h2 className="WorkshopsRoll__title">{title}</h2>
          }
          {text &&
            <p className="WorkshopsRoll__text">{text}</p>
          }
        </div>

        <ul className="WorkshopsRoll__items">
          {data.map((item, i) => (
            <WorkshopItem
              key={i}
              tagName="li"
              iconSrc={item.image.publicURL || item.image}
              title={item.title}
              url={item.slug}
              isNewDate={item.is_new_date}
            >
              {item.description}
            </WorkshopItem>
          ))}
        </ul>
      </div>
    </section>
  )
}

WorkshopsRoll.propTypes = {
  data: PropTypes.array
};

export default WorkshopsRoll;
